import { RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const PRButton = ({ to }) => {
  const navigate = useNavigate();
  return (
    <Button
      style={{ fontSize: "1em", height: "3em" }}
      type="primary"
      onClick={() => {
        navigate(to);
      }}>
      Continue <RightOutlined />
    </Button>
  );
};
