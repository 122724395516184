import logo from "./logo.svg";
import "./App.css";
import {
  BuildTwoTone,
  EnvironmentTwoTone,
  LogoutOutlined,
  MedicineBoxTwoTone,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusCircleTwoTone,
  RightOutlined,
  ScheduleTwoTone,
  SettingTwoTone,
  ShopTwoTone,
  SmileTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  Flex,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Space,
  theme,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import Start from "./components/StartingComp";
import Welcome from "./components/Welcome";
import PlanScreen from "./components/PlanScreen";
import Sider from "antd/es/layout/Sider";
import React, { useState } from "react";
import PAIHeader from "./components/PAIHeader";
import {
  LoginForm,
  loginExport,
  loginImport,
} from "./components/logicForLogin";

const { useToken } = theme;
// Check if the user is logged in
const loginCheck = () => {
  console.log(loginImport() === "loggedIn");
  return loginImport() === "loggedIn";
};

const logout = () => {
  loginExport("");
  window.location.reload();
};

function getMenu(key, label, icon, children) {
  return {
    key,
    label,
    icon,
    children,
  };
}

function App() {
  const { token } = useToken();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const onClick = (e) => {
    console.log(e);
    setCollapsed(true);
    if (e.key === "4") {
      setLogoutOpen(true);
      console.log("open");
    } else if (e.key === "1") {
      navigate("/");
    } else if (e.key === "2") {
      navigate("/Start");
    } else if (e.key === "3") {
      navigate("/Plan");
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          // Set the theme colors using state
          colorPrimary: "#7AC142",
          colorBgBase: "#282c34",
          borderRadius: "16px",
          colorTextBase: "#FFFFFF",
          colorInfo: "#6CA6C1",
          colorError: "#EF767A",
          colorSuccess: "#7FB069",
          colorWarning: "#EDAE49",
        },
      }}>
      <Modal
        open={logoutOpen}
        title="Log Out"
        onOk={logout}
        onCancel={() => setLogoutOpen(false)}>
        Are you sure you want to log out?
      </Modal>
      <div className="App">
        <Card
          style={{
            borderRadius: 0,
            overflow: "auto",
            overflowX: "hidden",
            zIndex: "1",
            height: "100vh",
          }}
          hidden={loginCheck()}>
          <LoginForm />
        </Card>
        {loginCheck() ? (
          <Layout style={{ height: "100vh" }} hidden={loginCheck()}>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{ height: "100%", overflow: "hidden", zIndex: "2" }}>
              <div className="wrapTrigger">
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    style: {
                      height: "7vh",
                      fontSize: "2em",
                      color: token.colorPrimary,
                      zIndex: "2",
                      textAlign: "center !important",
                    },
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
              </div>
              <Menu
                mode="inline"
                onClick={onClick}
                defaultOpenKeys={["sub1"]}
                style={{
                  height: "93vh",
                  borderRight: 0,
                  padding: 0,
                  zIndex: "2",
                  fontSize: "1.2em",
                }}
                items={[
                  getMenu(
                    1,
                    "Welcome",
                    <SmileTwoTone twoToneColor={token.colorPrimary} />
                  ),
                  getMenu(
                    2,
                    "Start",
                    <PlusCircleTwoTone twoToneColor={token.colorPrimary} />
                  ),
                  getMenu(
                    3,
                    "Plan",
                    <BuildTwoTone twoToneColor={token.colorPrimary} />
                  ),
                  getMenu(
                    4,
                    "Log out",
                    <LogoutOutlined style={{ color: "#ff9999" }} />
                  ),
                ]}
              />
            </Sider>

            <Layout
              className="site-layout"
              style={{ backgroundColor: "transparent", zIndex: "2" }}>
              <Header
                style={{
                  padding: 0,
                  background: token.colorBgContainer,
                  height: "7vh",
                }}>
                <PAIHeader />
              </Header>

              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/Start" element={<Start />} />
                <Route path="/Plan" element={<PlanScreen />} />
              </Routes>
            </Layout>
          </Layout>
        ) : (
          <></>
        )}
      </div>
    </ConfigProvider>
  );
}

export default App;
