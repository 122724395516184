import OpenAI from "openai";
import { useState } from "react";

const openai = new OpenAI({
  organization: "org-8RfXgAJafiyMD0hjH5uRw80q",
  apiKey: "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",
  dangerouslyAllowBrowser: true,
});

const tools = [
  {
    type: "function",
    function: {
      name: "return_project_plan",
      description:
        "Get plan for project, be sure to ask many questions range in quantity for asked task complexity, sort of quiz the user, in order to get a proper plan together, QUIZ QUESTIONS ARE TO BE ASK *ONE AT A TIME, NOT ALL AT ONCE* MUST STICK TO EXAMPLE FORMAT",
      parameters: {
        type: "object",
        properties: {
          title: {
            type: "string",
            description: "title of the project",
          },
          steps: {
            type: "string",
            description:
              "MUST be valid JSON object *ARRAY OF OBJECTS* as will be nestable but require a unique key, be sure if asked for changes, the steps retain most of that last function call where applicable.TASK ASSIGNMENT WILL BE PLACED AS THE CHILDREN OF AN OBJECT e.g. [{title: 'Milestone 1',key: '1',children: [{title: 'Step 1: this is a task',key:'1a',children: [{title: 'Assigned to: Firstname Lastname',key: '0-0-0-0'}]},{title: 'Step 2: this is another task',key: '0-0-1',children: [{title:'Assigned to: Firstname Lastname',key: '0-0-1-0'}]}]}]",
          },
        },
        required: ["title", "steps"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "get_user_info",
      description:
        "Use this function to ask the user any plan questions, *MUST HAVE OBJECTS IN OPTIONS ARRAY OBJECT WITH OPTION VALUES*",
      parameters: {
        type: "object",
        properties: {
          question: {
            type: "string",
            description: "Question asked to user",
          },
          options: {
            type: "string",
            description:
              "*MUST be valid JSON **ARRAY OF OBJECTS** or app will break* object Array of user options. e.g {[{option: 'yes'},{option: 'no'},{option: 'anything'}]}",
          },
        },
        required: ["question", "options"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "get_complexity_number",
      description:
        "Pass in the task title or description to get template, for now only has migration template",
      parameters: {
        type: "object",
        properties: {
          taskTitle: {
            type: "string",
            description: "only contains Migration template",
          },
        },
        required: ["taskTitle"],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "get_employee_schedule",
      description: "Call this to get employees' schedules",
      parameters: {
        type: "object",
        properties: {
          teamName: {
            type: "string",
            description: "Team name",
          },
        },
        required: ["teamName"],
      },
    },
  },
];

let complexityNum;

async function mainChat(
  text,
  { setResponse },
  { setProjectTree },
  { setRawFunction },
  { setChange },
  { setProjectTitle }
) {
  let stringTotal = "";
  let functionTotal = "";
  let functionType = "";
  console.log(text);
  console.log(setResponse);
  const stream = await openai.chat.completions.create({
    model: "gpt-3.5-turbo",
    messages: text,
    tools: tools,
    tool_choice: "auto",
    stream: true,
    seed: 123,
    temperature: 0,
  });
  for await (const chunk of stream) {
    console.log(chunk.choices[0]?.delta?.content || "");
    if (chunk.choices[0]?.finish_reason !== "stop") {
      stringTotal = stringTotal + chunk.choices[0]?.delta?.content || "";
      console.log({ chunk });
    }

    if (chunk.choices[0]?.delta?.tool_calls || "") {
      functionTotal =
        functionTotal +
          chunk.choices[0]?.delta?.tool_calls[0]?.function.arguments || "";
      console.log(functionTotal);
      if (chunk.choices[0]?.delta?.tool_calls[0]?.function?.name) {
        functionType = chunk.choices[0]?.delta?.tool_calls[0]?.function?.name;
      }
    }
    if (
      chunk.choices[0]?.finish_reason === "stop" ||
      chunk.choices[0]?.finish_reason === "tool_calls"
    ) {
      if (chunk.choices[0]?.finish_reason === "tool_calls") {
        if (functionType === "get_user_info") {
        } else if (functionType === "return_project_plan") {
          setProjectTitle(JSON.parse(functionTotal).title);
          setProjectTree(JSON.parse(JSON.parse(functionTotal).steps));
        } else if (functionType === "get_complexity_number") {
          text.push({
            role: "function",
            name: "returned_complexity_number",
            content: askComplexity(JSON.parse(functionTotal).taskTitle),
          });
          mainChat(
            text,
            { setResponse },
            { setProjectTree },
            { setRawFunction },
            { setChange },
            { setProjectTitle }
          );
        } else if (functionType === "get_employee_schedule") {
          text.push({
            role: "function",
            name: "returned_employee_schedule",
            content: getTeamSchedule(),
          });
          mainChat(
            text,
            { setResponse },
            { setProjectTree },
            { setRawFunction },
            { setChange },
            { setProjectTitle }
          );
        }
        console.log(functionType);

        setResponse(
          functionType === "get_user_info"
            ? "User Question"
            : functionType === "get_complexity_number"
            ? "Complexity Number"
            : functionType === "get_employee_schedule"
            ? "Employee Schedule"
            : "Project returned"
        );

        setRawFunction(functionTotal);
      } else {
        console.log({ chunk });
        console.log(stringTotal);
        setResponse(stringTotal);
      }
      setChange(chunk.created);
    }
  }
}

const askComplexity = (text) => {
  return "Scenario: Customer has a new physical Windows Server 2022 they bought to replace their old Windows Server 2012. The current server is their only server, serving as the file server, Active Directory, Domain Controller, DNS, and DHCP server. All functions need to be configured and migrated to the new server. The file share drive is currently mapped to all user devices using Group Policy, which also needs updating for the new server. Actual Project Steps: Rack and power on new server; Do new Windows Server configuration; Assign a hostname; Update Windows; Leave network adapter as DHCP if new server is to eventually take over the static IP of the old server, if not, assign static IP (This includes a decision tree for assigning a static IP, asking the user if they want to assign a static IP during initial setup, and if yes, asking for IP address, subnet mask, and gateway details); Install desired file migration software, asking the user if they have a preference or recommending Storage Migration Service, including steps for configuring the tool if chosen; Install security software and backup tool, asking for user preferences; Add Active Directory, DNS, and DHCP roles with instructions; Start running file syncs using migration tool; Set cut-over date with customer based on tool speed and file system size; On cut-over date, update Group Policy to map shared drive from new server, including instructions for updating drive mapping Group Policy.";
};

function getTeamSchedule() {
  const employees = [
    {
      id: 1,
      name: "Alice Johnson",
      role: "Software Developer",
      skills: ["JavaScript", "React", "Node.js"],
      schedule: [
        { day: "Monday", start: "09:00", end: "17:00" },
        { day: "Tuesday", start: "09:00", end: "17:00" },
        { day: "Wednesday", start: "09:00", end: "17:00" },
        { day: "Thursday", start: "10:00", end: "18:00" },
        { day: "Friday", start: "09:00", end: "17:00" },
      ],
    },
    {
      id: 2,
      name: "Bob Smith",
      role: "Network Engineer",
      skills: ["Cisco", "Juniper", "Network Security"],
      schedule: [
        { day: "Monday", start: "08:00", end: "16:00" },
        { day: "Tuesday", start: "08:00", end: "16:00" },
        { day: "Wednesday", start: "Remote", start: "None", end: "None" },
        { day: "Thursday", start: "08:00", end: "16:00" },
        { day: "Friday", start: "08:00", end: "16:00" },
      ],
    },
    {
      id: 3,
      name: "Charlie Davis",
      role: "System Administrator",
      skills: ["Linux", "VMware", "Storage Solutions"],
      schedule: [
        { day: "Monday", start: "Remote", start: "None", end: "None" },
        { day: "Tuesday", start: "10:00", end: "18:00" },
        { day: "Wednesday", start: "10:00", end: "18:00" },
        { day: "Thursday", start: "Remote", start: "None", end: "None" },
        { day: "Friday", start: "10:00", end: "18:00" },
      ],
    },
    {
      id: 4,
      name: "Jeff Lebowski",
      role: "System Administrator",
      skills: ["Linux", "VMware", "Storage Solutions"],
      schedule: [
        { day: "Monday", start: "Vacation", start: "None", end: "None" },
        { day: "Tuesday", start: "Vacation", start: "None", end: "None" },
        { day: "Wednesday", start: "Vacation", start: "None", end: "None" },
        { day: "Thursday", start: "Vacation", start: "None", end: "None" },
        { day: "Friday", start: "Vacation", start: "None", end: "None" },
      ],
    },
    // Add more employees as needed
  ];

  return JSON.stringify(employees);
}

export default mainChat;
