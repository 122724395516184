import { SmileOutlined } from "@ant-design/icons";
import { Button, Card, Col, Flex, Row, Space } from "antd";
import Title from "antd/es/typography/Title";
import { PRButton } from "./UIElements";
import Meta from "antd/es/card/Meta";

function Welcome() {
  return (
    <Flex gap="middle" align="center" vertical>
      <Flex align="center" style={{ height: "100vh", width: "80%" }} horizontal>
        <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
          <Card style={{ width: "100%", height: "12vh" }}>
            <Title> Welcome to Project IQ</Title>
          </Card>
          <Card
            style={{ width: "100%", height: "60vh" }}
            styles={{ body: { height: "100%" } }}>
            <Title level={3}>
              Project IQ is an AI project management tool in its early stages.
            </Title>
            <Title level={5}>
              Not everything works first try or at all. Purely a Proof of
              concept, this idea is meant to supplement existing project
              managers and make project plans more detailed and effective.
            </Title>
            <Row gutter={[16, 16]} style={{ height: "100%" }}>
              <Col span={8}>
                <Card
                  style={{ backgroundColor: "#994444", height: "79%" }}
                  styles={{ body: { height: "100%" } }}
                  cover={<img alt="example" src="/chatDemo.png" />}>
                  <Meta
                    title="User Friendly Chat"
                    description="IQ can collaborate with the user to help make more useful plans"
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  style={{ backgroundColor: "#449944", height: "79%" }}
                  styles={{ body: { height: "100%" } }}
                  cover={<img alt="example" src="/dynamicQuestions.png" />}>
                  <Meta
                    title="Dynamic Questions"
                    description="IQ can ask questions using a multiple choice format."
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card
                  style={{ backgroundColor: "#444499", height: "79%" }}
                  styles={{ body: { height: "100%" } }}
                  cover={<img alt="example" src="/employeeAssignment.png" />}>
                  <Meta
                    title="Employee Assignment"
                    description="IQ has access to availability and skill of employees for assignment."
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </Space>
      </Flex>
    </Flex>
  );
}

export default Welcome;
