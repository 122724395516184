import React, { useEffect, useRef, useState } from "react";
import { RightOutlined, SendOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Steps,
  Flex,
  Form,
  Space,
  Input,
  Divider,
  ConfigProvider,
  Tree,
} from "antd";
import Title from "antd/es/typography/Title";
import { PRButton } from "./UIElements";
import mainChat from "./chatGPT";
import { Navigate, useNavigate } from "react-router-dom";
import PlanScreen from "./PlanScreen";

const { Step } = Steps;

const cardBodyStyle = {
  body: {
    height: "100%",
  },
};

const messageCardBodyStyle = {
  body: {
    padding: "15px",
  },
};

let chatArray = [
  {
    role: "system",
    content:
      "You are an assistant named IQ developed by Project IQ to help with project manangement. You will follow these steps: Step 1: ask for the task from the user. Step 2: run that task into the get_complexity_number tool. Step 3: based on the returned complexity number (1-20), ask the user the same amount (1-20) of questions that may affect the plan steps using tool get_user_info repeatedly in a quiz-like manner e.g OS versions, etc. Step 4: Use the get_employee_schedule to find which personnel are available and add them to the plan for the next step. Step 5: use tool return_project_plan to return the plan to the user, do not ask for confirmation, just return it. Step 6: if user asks for changes to plan, implement and return using tool return_project_plan **RULES** 1: !!!YOU MAY NOT ASK PLAN QUESTIONS IN THE CHAT WITHOUT USING get_user_info.!!! 2: YOU MAY NOT RETURN PROEJCT PLAN WITHOUT USING return_project_plan",
  },
];

function Start() {
  const [currentStep, setCurrentStep] = useState(0);
  const userColor = "#B0BEC5";
  const aiColor = "#7AC142";
  const aiFontColor = "#FFFFFF";
  const projectColor = "#007aff";
  const userFontColor = "#000000";
  const messageRef = useRef(null);
  const [text, setText] = useState("");
  const [response, setResponse] = useState();
  const [chatArea, setChatArea] = useState();
  const [projectTree, setProjectTree] = useState();
  const [rawFunction, setRawFunction] = useState();
  const [projectTitle2, setProjectTitle] = useState();
  const [projectMade, setProjectMade] = useState(false);
  const [change, setChange] = useState();
  const [planConfirmed, setPlanConfirmed] = useState(false);
  const navigate = useNavigate();

  function updateChat() {
    setText("");

    setChatArea(
      chatArray.map((chat, index) => {
        if (chat.role !== "system") {
          {
            console.log(chat);
          }
          return (
            <Flex justify={chat.role === "user" ? "flex-end" : "flex-start"}>
              {chat?.name === "get_complexity_number" ||
              chat?.name === "returned_complexity_number" ||
              chat?.name === "user_answered" ||
              chat?.name === "returned_employee_schedule" ||
              chat?.name === "get_employee_schedule" ? (
                <></>
              ) : (
                <Card
                  style={{
                    color: chat.role === "user" ? userFontColor : aiFontColor,
                    textAlign: chat.role === "user" ? "right" : "left",
                    backgroundColor:
                      chat.role === "user"
                        ? userColor
                        : chat.role === "function"
                        ? projectColor
                        : aiColor,
                    width: "fit-content",
                    borderRadius:
                      chat.role === "user"
                        ? "32px 0 32px 32px"
                        : "0px 32px 32px 32px",
                  }}
                  styles={messageCardBodyStyle}>
                  {chat.role === "function" ? (
                    chat.name === "return_project_plan" ? (
                      "Press Next!"
                    ) : (
                      <>
                        <Space direction="vertical">
                          {JSON.parse(chat.content).question}
                          <Divider />

                          {JSON.parse(JSON.parse(chat.content).options).map(
                            (object, i) => {
                              {
                                console.log(object.option);
                              }
                              return (
                                <Button
                                  style={{ width: "100%" }}
                                  hidden={false}
                                  onClick={() => {
                                    chatArray.push({
                                      role: "function",
                                      name: "user_answered",
                                      content:
                                        "user answered " +
                                        object.option +
                                        ". if you need to ask more questions, use get_user_info again.",
                                    });
                                    updateChat();

                                    mainChat(
                                      chatArray,
                                      { setResponse },
                                      { setProjectTree },
                                      { setRawFunction },
                                      { setChange },
                                      { setProjectTitle }
                                    );
                                  }}>
                                  {object.option}
                                </Button>
                              );
                            }
                          )}
                        </Space>
                      </>
                    )
                  ) : (
                    chat.content
                  )}
                </Card>
              )}
            </Flex>
          );
        }
      })
    );
    console.log(chatArray);
  }
  useEffect(() => {
    if (response) {
      if (response === "Project returned") {
        chatArray.push({
          role: "function",
          name: "return_project_plan",
          content: rawFunction,
        });
        setProjectMade(true);
      } else if (response === "User Question") {
        chatArray.push({
          role: "function",
          name: "get_user_info",
          content: rawFunction,
        });
      } else {
        if (response === "Complexity Number") {
          chatArray.push({
            role: "function",
            name: "get_complexity_number",
            content: rawFunction,
          });
        } else if (response === "Employee Schedule") {
          chatArray.push({
            role: "function",
            name: "get_employee_schedule",
            content: rawFunction,
          });
        } else {
          chatArray.push({ role: "assistant", content: response });
        }
      }
      updateChat();
    }
    console.log(projectTree);
    console.log(chatArray);
  }, [change]);

  useEffect(() => {
    messageRef?.current?.scrollTo(0, 10000000);
    console.log(messageRef.current);
  }, [chatArray.length]);

  // Define your steps titles or content here
  const steps = [
    {
      title: "Define Task",
      content: (
        <>
          <Title>Let's start with what you'd like to do!</Title>
          <Card>
            <Space>
              <Title>Project Task: </Title>{" "}
              <ConfigProvider
                theme={{
                  token: {
                    // Set the theme colors using state
                    colorPrimary: "#7AC142",
                    colorBgBase: "#eeeeee",
                    borderRadius: "16px",
                    colorTextBase: "#111111",
                    colorInfo: "#6CA6C1",
                    colorError: "#EF767A",
                    colorSuccess: "#7FB069",
                    colorWarning: "#EDAE49",
                  },
                }}>
                <Input
                  name="planTitle"
                  size="large"
                  style={{ width: "30vw" }}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </ConfigProvider>
            </Space>
          </Card>
        </>
      ),
    },
    {
      title: "Discuss",
      content: (
        <>
          <Title>Lets get some questions in</Title>
          <Card>
            <ConfigProvider
              theme={{
                token: {
                  // Set the theme colors using state
                  colorPrimary: "#7AC142",
                  colorBgBase: "#eeeeee",
                  borderRadius: "16px",
                  colorTextBase: "#111111",
                  colorInfo: "#6CA6C1",
                  colorError: "#EF767A",
                  colorSuccess: "#7FB069",
                  colorWarning: "#EDAE49",
                },
              }}>
              <Card style={{ height: "100%" }} styles={cardBodyStyle}>
                <Flex
                  justify={"center"}
                  align={"flex-end"}
                  style={{ height: "100%", width: "100%" }}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <div
                      style={{
                        height: "30vh",
                        overflowY: "scroll",
                        width: "calc(100% + 24px)",
                      }}
                      ref={messageRef}>
                      {chatArea}
                    </div>
                    <Space.Compact
                      style={{
                        width: "100%",
                      }}>
                      <Input
                        placeholder="Send Message To AI.."
                        size="large"
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            chatArray.push({ role: "user", content: text });
                            updateChat();
                            mainChat(
                              chatArray,
                              { setResponse },
                              { setProjectTree },
                              { setRawFunction },
                              { setChange },
                              { setProjectTitle }
                            );
                          }
                        }}
                      />
                      <Button
                        size="large"
                        onClick={() => {
                          chatArray.push({ role: "user", content: text });
                          updateChat();
                          mainChat(
                            chatArray,
                            { setResponse },
                            { setProjectTree },
                            { setRawFunction },
                            { setChange },
                            { setProjectTitle }
                          );
                        }}>
                        <SendOutlined />
                      </Button>
                    </Space.Compact>
                  </Space>{" "}
                </Flex>{" "}
              </Card>
            </ConfigProvider>
          </Card>
        </>
      ),
    },
    {
      title: "Confirm",
      content: (
        <>
          <Title>Lets see the current plan, does this work?</Title>
          <Card>
            <Tree
              showLine
              checkable
              defaultExpandAll={true}
              treeData={projectTree ? projectTree : []}
            />
          </Card>
        </>
      ),
    },
  ];

  const next = () => {
    console.log(currentStep);
    if (currentStep === 0) {
      chatArray.push({ role: "user", content: text });
      updateChat();
      mainChat(
        chatArray,
        { setResponse },
        { setProjectTree },
        { setRawFunction },
        { setChange },
        { setProjectTitle }
      );
    }
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return planConfirmed ? (
    <PlanScreen
      chatAreaImport={chatArea}
      projectTreeImport={{ projectTree }}
      title={projectTitle2}
    />
  ) : (
    <Flex gap="middle" align="center" vertical>
      <Flex align="center" style={{ height: "100vh", width: "80%" }} horizontal>
        <Space direction="vertical" style={{ width: "100%" }} size={"large"}>
          <Title>Let's create the Project, its as easy as 1, 2, 3!</Title>
          <Steps current={currentStep}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[currentStep].content}</div>
          <div className="steps-action">
            {currentStep > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                disabled={
                  (text === "" && currentStep === 0) ||
                  (!projectMade && currentStep === 1)
                }>
                Next
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {
                  alert("Process complete!");
                  setPlanConfirmed(true);
                }}
                disabled={!projectMade}>
                Done
              </Button>
            )}
          </div>
        </Space>
      </Flex>
    </Flex>
  );
}

export default Start;
